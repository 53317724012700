import { throwError } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Store } from '@ngxs/store';
import { LocalStorage } from 'ngx-webstorage';

import { AccountState } from '@shared/states/account.state';
import { AuthState } from '@shared/states/auth.state';
import { DialogControl } from '@shared/services/dialog-control.service';

import { environment } from '@env/environment';
import { GetChatPrefs, UpdateChatPrefs } from '@shared/states/prefs.actions';

@Injectable({
  providedIn: 'root',
})
export class SupportChatService {
  @LocalStorage('consent') trackingAllowed: boolean | undefined;

  constructor(
    private store: Store,
    private http: HttpClient,
    private dc: DialogControl,
  ) {}

  public init() {
    this.store.dispatch(new GetChatPrefs());
  }

  public sendMessage(messages: any[] = []) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken: string = this.store.selectSnapshot(AuthState.authToken);

    const url: string = `https:${environment.apiServer}/ai/support/defaultSupportChat`;
    const data: { [prop: string]: any } = {
      temperature: 0,
      topP: 1,
      maxTokens: 800,
      modelKey: 'GPT-4-turbo',
      messages,
      aiService: 'cs',
    };

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    return this.http
      .post(url, data, {
        headers,
        withCredentials: this.trackingAllowed || false,
      })
      .pipe(
        timeout(300000),
        catchError((error: any) => {
          if (error && error.status !== 403) {
            this.dc.openErrorDialog(
              $localize`:@@zef-i18n-00040:Server Error`,
              $localize`Uups... Chat was not available. Try again later.`,
            );
          }

          return throwError(error);
        }),
      );
  }

  saveChat(messages: any[] = [], id?: number) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken: string = this.store.selectSnapshot(AuthState.authToken);

    const requestUrl: string = `https:${environment.apiServer}/cs/chat`;
    const url: string = window.location.href;
    const data: { [prop: string]: any } = {
      messages,
      url,
      ...(id && { id }),
    };

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    return this.http
      .post(requestUrl, data, {
        headers,
        withCredentials: this.trackingAllowed || false,
      })
      .pipe(
        timeout(300000),
        catchError((error: any) => {
          if (error && error.status !== 403) {
            this.dc.openErrorDialog($localize`:@@zef-i18n-00040:Server Error`, $localize`Uups... Chat was not saved.`);
          }

          return throwError(error);
        }),
        tap((response: any) => {
          if (response?.id != null && response?.time != null) {
            this.store.dispatch(
              new UpdateChatPrefs({
                latestChatId: response.id,
                latestChatDate: new Date(response.time).valueOf(),
              }),
            );
          }
        }),
      );
  }

  resetChat() {
    this.store.dispatch(new UpdateChatPrefs({ latestChatId: null, latestChatDate: null }));
  }

  getLatestChat(chatId: number) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken: string = this.store.selectSnapshot(AuthState.authToken);

    const requestUrl: string = `https:${environment.apiServer}/cs/chat/${chatId}`;

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    return this.http
      .get(requestUrl, {
        headers,
        withCredentials: this.trackingAllowed || false,
      })
      .pipe(
        timeout(300000),
        catchError((error: any) => {
          if (error && error.status !== 403) {
            this.dc.openErrorDialog($localize`:@@zef-i18n-00040:Server Error`, $localize`Uups... Chat was not loaded.`);
          }

          return throwError(error);
        }),
      );
  }
}
