import 'hammerjs';

import '@angular/localize/init';

import { environment } from '@env/environment';

import { enableProdMode } from '@angular/core';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { assertLocale } from '@shared/utilities/locales.utilities';
import { disableConsole } from '@shared/utilities/console.utilities';
import { getTranslations } from '@shared/utilities/translations.utilities';

import { ZefModule } from './app/zef.module';

if (environment.production) {
  enableProdMode();
  disableConsole();
}

getTranslations(assertLocale())
  .then(() => platformBrowserDynamic().bootstrapModule(ZefModule))
  .catch(() => {
    window['lang'] = 'en';

    return platformBrowserDynamic().bootstrapModule(ZefModule);
  });
