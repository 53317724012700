import { BehaviorSubject, Observable } from 'rxjs';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Select } from '@ngxs/store';
import { NgScrollbar } from 'ngx-scrollbar';

import { PrefsState } from '@shared/states/prefs.state';
import { getLastValue } from '@shared/operators/share-ref.operator';

import { SupportChatService } from '../../services/support-chat.service';

@Component({
  selector: 'support-chat-interface',
  templateUrl: './support-chat-interface.component.html',
  styleUrls: ['./support-chat-interface.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportChatInterface implements OnInit {
  @Output() closeChat = new EventEmitter<void>();

  @Select(PrefsState.latestChatDetails)
  readonly latestChat$!: Observable<any>;

  @ViewChild(NgScrollbar)
  sb: NgScrollbar;

  readonly startingMessage: string = $localize`Hello! How can I help you today?`;
  private chatId: number = null;

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public messages: any[] = [];

  constructor(
    readonly cdRef: ChangeDetectorRef,
    private sc: SupportChatService,
  ) {}

  ngOnInit(): void {
    this.sc.init();

    if (
      this.chatId == null &&
      getLastValue(this.latestChat$)?.latestChatId != null &&
      getLastValue(this.latestChat$)?.latestChatDate > new Date().valueOf() - 1000 * 60 * 60 * 3
    ) {
      this.chatId = getLastValue(this.latestChat$)?.latestChatId;

      this.sc.getLatestChat(this.chatId).subscribe((response: any) => {
        if (response?.messages?.length) {
          this.messages = response.messages;
          this.cdRef.detectChanges();
        } else {
          this.startNewChat();
        }

        this.scrollBottom();
      });
    } else {
      this.startNewChat();
    }
  }

  trackByMessage(index: number, message: any): string {
    return message?.message;
  }

  addComment(comment: string): void {
    const actualComment = comment.trim();

    if (actualComment) {
      this.messages.push({ message: actualComment, role: 'user' });
      this.cdRef.detectChanges();

      this.loading$.next(true);
      this.cdRef.detectChanges();

      this.scrollBottom();

      this.sc.sendMessage(this.messages).subscribe((response) => {
        const messages = response?.[0]?.messages
          ?.filter((message) => message.role === 'assistant')
          ?.map((message) => ({ message: message?.content?.replace(/\[doc([^[\]]+)]/g, ''), role: 'assistant' }));

        if (messages && messages.length > 0) {
          this.messages = this.messages.concat(messages);
        }

        this.loading$.next(false);
        this.cdRef.detectChanges();

        this.sb.scrollTo({ bottom: 0 });
        this.cdRef.detectChanges();

        this.sc.saveChat(this.messages, this.chatId).subscribe();
      });
    }
  }

  reset(): void {
    this.messages = [];
    this.chatId = null;
    this.sc.resetChat();
    this.startNewChat();
  }

  startNewChat(): void {
    this.messages.push({ message: this.startingMessage, role: 'assistant' });
    this.cdRef.detectChanges();
  }

  scrollBottom(): void {
    this.sb.scrollTo({ bottom: 0 });
    this.cdRef.detectChanges();
  }

  onValueChanged(): void {
    /* This seems to trigger the needed change detection for input value in template */
  }
}
