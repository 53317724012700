<div
  *ngIf="showBubble$ | async"
  class="help-container"
  (cdkDragStarted)="onDragStart()"
  (cdkDragEnded)="onDragEnd()"
  cdkDrag
  cdkDragBoundary="body"
>
  <support-chat-interface *ngIf="aiChatOpen" (closeChat)="aiChatOpen = false"></support-chat-interface>
  <!-- <div id="hubspotChatContainer" class="zef-card-z1 chat-embedded-container" [class.chatOpen]="chatOpen">
    <div *ngIf="chatOpen && loadingChat" class="z-fx-col z-fx-fill z-fx-center-center loading">
      <span i18n>Loading chat...</span>
    </div>
    <div *ngIf="chatOpen && chatNotLoaded" class="z-fx-col z-fx-fill z-fx-center-center unavailable">
      <span i18n>Chat is not available...</span>
    </div>
  </div> -->

  <button
    @scale
    class="zef-fab-teardrop chat"
    mat-icon-button
    color="accent"
    [class.active]="menuTrigger?.menuOpen || hovering || aiChatOpen"
    [class.disable-menu]="dragging"
    [matMenuTriggerFor]="chatMenu"
    (menuClosed)="onMenuClosed()"
    (menuOpened)="onMenuOpened()"
    (@scale.done)="onBubbleScaleDone($event)"
    (click)="$event.stopPropagation()"
    (mouseenter)="hovering = true"
    (mouseleave)="hovering = false"
    #menuTrigger="matMenuTrigger"
  >
    <mat-icon
      class="zef-icon-large"
      [@flip]="{ value: !menuTrigger.menuOpen && !dragMenuOpen && !(chatOpen || aiChatOpen), params: { rotate: 0 } }"
    >
      {{ (canChat$ | async) ? 'chat' : 'action_academy' }}
    </mat-icon>

    <mat-icon
      [@flip]="{ value: menuTrigger.menuOpen || chatOpen || aiChatOpen || dragMenuOpen, params: { rotate: 180 } }"
      class="chat-expand"
      [class.chat-hidden]="hiding"
    >
      close
    </mat-icon>

    <mat-menu class="zef-menu-offset-y-16" yPosition="above" [hasBackdrop]="false" #chatMenu>
      <div *matMenuContent class="chat-menu z-fx-col z-fx-gap-24">
        <div class="z-fx-col z-fx-gap-16">
          <div class="zef-text-h3-muted" i18n>Top suggestions</div>

          <div class="chat-menu-items">
            <a
              *ngFor="let item of menuItems$ | async"
              class="z-fx-sc z-fx-gap-8 zef-text-p1 zef-interactive chat-menu-item zef-color-ink zef-text-no-decoration"
              [href]="item.link"
              target="_blank"
              rel="noreferrer nofollow"
            >
              <mat-icon>{{ item.icon }}</mat-icon>
              <span>{{ item.label }}</span>
            </a>
          </div>
        </div>

        <div class="z-fx-col z-fx-gap-16">
          <div
            *ngIf="canChat$ | async"
            class="zef-card-z-1-soft zef-card-24 z-fx z-fx-between-center z-fx-gap-16"
            (click)="$event.stopPropagation()"
          >
            <span class="zef-text-p1" i18n>Looking for help?</span>

            <button mat-raised-button color="primary" (click)="startAIChat()" i18n>Chat</button>
          </div>

          <div class="zef-text-p2-muted zef-text-link-hover-underline chat-hide" (click)="onHideBubble()" i18n>
            Hide bubble
          </div>
        </div>
      </div>
    </mat-menu>
  </button>
</div>
