import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DomPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Inject, ComponentFactoryResolver, ApplicationRef, Injector } from '@angular/core';

import { Store, Select } from '@ngxs/store';

import { BillingState } from '@shared/states/billing.state';
import { LicenseFeature } from '@shared/enums/license-feature.enum';
import { ChatBubbleComponent } from '@shared/modules/chat/components/chat-bubble.component';
import { GlobalSpinnerService } from '@shared/services/global-spinner.service';
import { GetChatPrefs } from '@shared/states/prefs.actions';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  @Select(BillingState.featureActive(LicenseFeature.ChatSupport))
  readonly canChat$!: Observable<boolean>;

  private outlet?: DomPortalOutlet;

  private portal = new ComponentPortal(ChatBubbleComponent);

  constructor(
    private store: Store,
    private oc: OverlayContainer,
    private cf: ComponentFactoryResolver,
    private inj: Injector,
    private gs: GlobalSpinnerService,
    @Inject(DOCUMENT) private doc: Document,
  ) {}

  init(): Observable<void> {
    this.create();
    this.listen();

    return this.store.dispatch(new GetChatPrefs());
  }

  showBubble(): void {
    if (this.outlet && !this.outlet.hasAttached()) {
      this.outlet.attachComponentPortal(this.portal);
    }
  }

  hideBubble(): void {
    if (this.outlet?.hasAttached()) {
      this.outlet.detach();
    }
  }

  private create(): void {
    const pane = this.doc.createElement('div');
    this.oc.getContainerElement().appendChild(pane);

    this.outlet = new DomPortalOutlet(pane, this.cf, this.inj.get(ApplicationRef), this.inj, this.doc);
  }

  private listen(): void {
    this.gs.appLoadComplete$.pipe(tap(() => this.showBubble())).subscribe();
  }
}
