/**
 * Main application component.
 *
 * @stable
 */

import { merge, fromEvent } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';

import { environment } from '@env/environment';

import { Store } from '@ngxs/store';

import { AuthState } from '@shared/states/auth.state';

import { Component, ChangeDetectionStrategy } from '@angular/core';

import { ZefErrorHandler } from '@shared/error.handler';
import { AppUpdate } from '@shared/services/app-update.service';
import { AccountManager } from '@shared/services/account-manager.service';

import packageJson from '../../package.json';

@Component({
  selector: 'zef-app',
  templateUrl: './zef.component.html',
  styleUrls: ['./zef.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZefApp {
  public build: string = '';
  public version: string = packageJson.version;

  readonly Environment = environment;

  constructor(
    readonly errorHandler: ZefErrorHandler,
    private au: AppUpdate,
    private am: AccountManager,
    private store: Store,
  ) {
    if (
      window.location.href.indexOf('http://') === 0 &&
      window.location.href.indexOf('192.168.') === -1 &&
      window.location.href.indexOf('localhost') === -1
    ) {
      window.location.href = window.location.href.replace('http://', 'https://');
    } else {
      this.build = environment.debug ? 'Beta' : '';

      console.log($localize`:console log for app version@@zef-i18n-00000:App version: ${this.version}:INTERPOLATION:`);
    }

    merge(
      fromEvent(document, 'wheel').pipe(map(() => true)),
      fromEvent(document, 'scroll').pipe(map(() => true)),
      fromEvent(document, 'keydown').pipe(map(() => true)),
      fromEvent(document, 'mousedown').pipe(map(() => true)),
      fromEvent(document, 'mousemove').pipe(map(() => true)),
      fromEvent(document, 'touchstart').pipe(map(() => true)),
    )
      .pipe(throttleTime(5 * 60 * 1000))
      .subscribe(() => {
        const userInfo = this.store.selectSnapshot(AuthState.info);

        if (!!userInfo && !userInfo.isAnonymous) {
          this.am.updateLastActivityTime(userInfo.uid);
        }
      });
  }
}
