<!--
  Main router element and build info.

  @stable
-->

<zef-global-spinner></zef-global-spinner>

<router-outlet></router-outlet>

<div *ngIf="build" class="zef-build">{{ build }} build, version: {{ version }}</div>

<div *ngIf="build && (errorHandler.error | async)" class="zef-error" (click)="errorHandler.clearError()">
  Errors detected, check the console for more information.
</div>

<div class="zef-portal"></div>
