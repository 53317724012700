import { concat, interval } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { SwUpdate } from '@angular/service-worker';
import { ApplicationRef, Injectable, Optional } from '@angular/core';

import { Store } from '@ngxs/store';

import { DisplaySnackbar } from '@shared/states/dialog.actions';

@Injectable({
  providedIn: 'root',
})
export class AppUpdate {
  constructor(appRef: ApplicationRef, store: Store, @Optional() updates: SwUpdate) {
    if (updates?.isEnabled) {
      console.log('Service worker enabled');

      const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable));
      const checkInterval$ = interval(15 * 60 * 1000);
      const everyHourOnceAppIsStable$ = concat(appIsStable$, checkInterval$);

      everyHourOnceAppIsStable$.subscribe(() => {
        updates.checkForUpdate();
      });

      updates.versionUpdates.pipe(filter((update) => update.type === 'VERSION_READY')).subscribe(() => {
        console.log('New version available');

        store.dispatch(
          new DisplaySnackbar($localize`:@@zef-i18n-00016:A new version of Zeffi is available`, {
            color: 'success',
            timeout: 7 * 24 * 60 * 60 * 1000,
            actionName: $localize`:@@zef-i18n-00017:Refresh`,
            actionCallback: () => window.location.reload(),
          }),
        );
      });
    }
  }
}
