/**
 * ZEF module used by all modules.
 *
 * @stable
 */
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@env/environment';

import localeFi from '@angular/common/locales/fi';

import { AngularFireModule } from '@angular/fire/compat';
import {
  APP_BASE_HREF,
  LocationStrategy,
  PathLocationStrategy,
  PlatformLocation,
  registerLocaleData,
} from '@angular/common';
import { APP_INITIALIZER, ApplicationRef, DoBootstrap, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';

import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxsModule, Store } from '@ngxs/store';

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';

import { AnalyticsMediator } from '@shared/services/analytics-mediator.service';

import { NgEventOptionsModule } from 'ng-event-options';

import { SharedModule } from '@shared/shared.module';
import { ZefErrorHandler } from '@shared/error.handler';

import { AppUpdate } from '@shared/services/app-update.service';
import { CustomRouterStateSerializer } from '@shared/serializers/custom-router-state.serializer';

import { BrowserState } from '@shared/states/browser.state';
import { BillingModule } from '@home/shared/modules/billing.module';
import { InitAuthentication } from '@shared/states/auth.actions';
import { ActionsState } from '@shared/states/actions.state';
import { DialogState } from '@shared/states/dialog.state';
import { ChatService } from '@shared/modules/chat/services/chat.service';
import { ChatModule } from '@shared/modules/chat/chat.module';
import { ZefPreload, ZefRouting } from './zef.routing';
import { ZefApp } from './zef.component';

registerLocaleData(localeFi, 'fi-FI');

@NgModule({
  bootstrap: [],
  declarations: [ZefApp],
  imports: [
    ZefRouting,
    ChatModule,
    SharedModule,
    BillingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    NgEventOptionsModule,
    NgxsModule.forRoot([BrowserState, ActionsState, DialogState], {
      developmentMode: !environment.production,
      selectorOptions: {
        suppressErrors: false,
      },
    }),
    NgxsDispatchPluginModule,
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: !environment.debug,
      stateSanitizer,
      actionSanitizer,
    }),
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebase, 'zefApp'),
    environment.production
      ? ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          registrationStrategy: 'registerWhenStable:3000',
        })
      : [],
  ],
  providers: [
    AppUpdate,
    ZefPreload,
    ZefErrorHandler,
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: ErrorHandler,
      useExisting: ZefErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: authInitializer,
      deps: [Store, AnalyticsMediator],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: chatInitializer,
      deps: [ChatService],
      multi: true,
    },
    {
      provide: SwRegistrationOptions,
      useFactory: provideSWOptions,
    },
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer,
    },
    {
      provide: LocationStrategy,
      useFactory: provideLocationStrategy,
      deps: [Injector],
    },
  ],
})
export class ZefModule implements DoBootstrap {
  async ngDoBootstrap(app: ApplicationRef) {
    await new Promise<void>((resolve) => {
      const waitForAppEl = () => (document.querySelector('zef-app') ? resolve() : setTimeout(waitForAppEl, 10));

      waitForAppEl();
    });

    app.bootstrap(ZefApp);
  }
}

export function provideSWOptions() {
  return { enabled: true, registrationStrategy: 'registerImmediately' };
}

export function provideLocationStrategy(injector: Injector): LocationStrategy {
  return new PathLocationStrategy(injector.get(PlatformLocation), injector.get(APP_BASE_HREF));
}

export function authInitializer(store: Store, am: AnalyticsMediator): () => Observable<void> {
  if (environment.debug) {
    (window as any)['ngxs'] = store;
  }

  return () => store.dispatch(new InitAuthentication()).pipe(tap(() => am.initialize()));
}

export function chatInitializer(cs: ChatService): () => Observable<void> {
  return () => cs.init();
}

export function stateSanitizer(state: any): any {
  return { ...state /* , templates: null  */ };
}

export function actionSanitizer(action: any): any {
  if (action?.type.startsWith('[Router]')) {
    delete action?.routerState;
    delete action?.event;
  }

  return action;
}
