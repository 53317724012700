import { NgModule } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';

import { NgxsModule } from '@ngxs/store';

import { ChatState } from '@shared/modules/chat/chat.state';
import { ChatBubbleComponent } from '@shared/modules/chat/components/chat-bubble.component';
import { SupportChatInterface } from '@shared/modules/chat/components/support-chat-interface/support-chat-interface.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    MatIconModule,
    OverlayModule,
    PortalModule,
    NgxsModule.forFeature([ChatState]),
    MatButtonModule,
    MatMenuModule,
    MatInputModule,
    DragDropModule,
    NgScrollbarModule,
    CommonModule,
  ],
  declarations: [ChatBubbleComponent, SupportChatInterface],
})
export class ChatModule {}
