/**
 * App routing for the service.
 *
 * @stable
 */

import { environment } from '@env/environment';

import { Observable, of, timer } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { ModuleWithProviders } from '@angular/core';
import { PreloadingStrategy, Route, RouterModule, Routes } from '@angular/router';
import { authMatcher } from '@auth/auth.routing';

export const ZefRoutes: Routes = [
  { path: 'plans', loadChildren: () => import('@plans/plans.module').then((m) => m.PlansModule) },

  { matcher: authMatcher, loadChildren: () => import('@auth/auth.module').then((m) => m.AuthModule) },

  // Report preview route within the main application itself

  { path: 'preview', loadChildren: () => import('@report/report.module').then((m) => m.ReportModule) },
  { path: 'report', loadChildren: () => import('@report/report.module').then((m) => m.ReportModule) },

  // Website based login links are handled by the home module

  { path: '', pathMatch: 'prefix', loadChildren: () => import('@home/home.module').then((m) => m.HomeModule) },
];

export class ZefPreload implements PreloadingStrategy {
  private noPreloadPaths = ['admin'];

  private preloadIdx = -1;

  preload(route: Route, load: any): Observable<any> {
    if (!environment.production || this.noPreloadPaths.includes(route.path)) {
      return of(null);
    }

    this.preloadIdx++;

    return timer(15000 + 1000 * this.preloadIdx).pipe(concatMap(() => load()));
  }
}

export const ZefRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot(ZefRoutes, {
  paramsInheritanceStrategy: 'always',
  preloadingStrategy: ZefPreload,
  enableTracing: false,
});
