import { StateToken } from '@ngxs/store';

import { PlanId } from '@shared/models/plan.model';

export interface ChatStateModel {
  plan: PlanId;
  team: string;
  user: string;
  email: string;
  rights: string;
  survey: string;
  activity: string;
  language: string;
  chatting: boolean;
}

export const CHAT_STATE_TOKEN = new StateToken<ChatStateModel>('chat');
