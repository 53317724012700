import { debounceTime, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { animationFrameScheduler, combineLatest, Observable, of, Subject, timer } from 'rxjs';

import { AnimationEvent } from '@angular/animations';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';

import { shareRef } from '@shared/operators/share-ref.operator';
import { PrefsState } from '@shared/states/prefs.state';
import { BillingState } from '@shared/states/billing.state';
import { OpenUserMenu } from '@shared/states/interaction.actions';
import { FlipAnimation } from '@shared/animations/flip.anim';
import { ScaleAnimation } from '@shared/animations/scale.anim';
import { LicenseFeature } from '@shared/enums/license-feature.enum';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { DisplaySnackbar } from '@shared/states/dialog.actions';
import { HideChatBubble, ShowChatBubble, StartChat } from '@shared/modules/chat/chat.actions';
import { AccountState } from '@shared/states/account.state';

@Component({
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss'],
  animations: [FlipAnimation, ScaleAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LifecycleHooks],
})
export class ChatBubbleComponent implements OnInit {
  @Select(BillingState.featureActive(LicenseFeature.ChatSupport))
  readonly canChat$!: Observable<boolean>;

  @ViewChild('menuTrigger', { read: MatMenuTrigger })
  menuTrigger?: MatMenuTrigger;

  dragging?: boolean;

  dragMenuOpen: boolean = false;

  hiding?: boolean;

  hovering?: boolean;

  chatOpen: boolean = false;

  loadingChat: boolean = false;

  chatNotLoaded: boolean = false;

  aiChatOpen: boolean = false;

  readonly bubbleShowDone$ = new Subject<void>();

  readonly bubbleHideDone$ = new Subject<void>();

  readonly menuItems$ = this.store.select(PrefsState.language).pipe(
    map((lang) => (lang === 'fi' ? 'fi' : 'en')),
    map((lang) => [
      {
        icon: 'action_academy',
        label: $localize`Help Center`,
        link: { en: 'https://help.zef.fi/knowledge/', fi: 'https://help.zef.fi/fi/knowledge/' }[lang],
      },
      {
        icon: 'bulb',
        label: $localize`FAQs`,
        link: {
          en: 'https://help.zef.fi/knowledge/faq',
          fi: 'https://help.zef.fi/fi/knowledge/ukk',
        }[lang],
      },
      {
        icon: 'recommended',
        label: $localize`Feedback / Report bug`,
        link: {
          en: 'https://help.zef.fi/knowledge/kb-tickets/new',
          fi: 'https://help.zef.fi/fi/knowledge/kb-tickets/new',
        }[lang],
      },
    ]),
  );

  private showBubblePrefs$ = this.store.select(PrefsState.helpHidden).pipe(
    debounceTime(1),
    map((hidden) => !hidden),
    shareRef(),
  );

  private isValidIdentity$ = this.store.select(AccountState.isValidIdentity);

  readonly showBubble$ = combineLatest([this.isValidIdentity$, this.showBubblePrefs$]).pipe(
    map(([isValidIdentity, showBubblePrefs]) => isValidIdentity && showBubblePrefs),
  );

  constructor(
    readonly cdRef: ChangeDetectorRef,
    readonly store: Store,
    readonly actions: Actions,
    private lh: LifecycleHooks,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.actions
      .pipe(
        ofActionDispatched(ShowChatBubble),
        switchMap(() => (this.menuTrigger ? of(void 0) : this.bubbleShowDone$.pipe(take(1)))),
        takeUntil(this.lh.destroy),
      )
      .subscribe(() => {
        if (this.menuTrigger && !this.chatOpen) {
          this.menuTrigger.openMenu();
          this.cdRef.detectChanges();
        }
      });

    this.actions.pipe(ofActionDispatched(StartChat)).subscribe(() => {
      this.startChat();
    });

    this.router.events
      .pipe(
        filter(() => this.chatOpen),
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.lh.destroy),
      )
      .subscribe(() => {
        if (window.HubSpotConversations) {
          window.HubSpotConversations.widget.refresh();
        }
      });
  }

  isChatAvailable(): boolean {
    return !!window.HubSpotConversations?.widget;
  }

  startAIChat(): void {
    if (this.menuTrigger?.menuOpen) {
      this.menuTrigger.closeMenu();
    }

    this.aiChatOpen = true;
    this.cdRef.detectChanges();
  }

  startChat(): void {
    if (this.menuTrigger?.menuOpen) {
      this.menuTrigger.closeMenu();
    }

    this.loadingChat = true;
    this.chatOpen = true;

    this.cdRef.detectChanges();

    if (window.HubSpotConversations?.widget?.status()?.loaded !== true) {
      timer(0, 250, animationFrameScheduler)
        .pipe(
          filter((time) => time > 120 || window.HubSpotConversations?.widget?.status()?.loaded),
          take(1),
        )
        .subscribe(() => {
          this.loadingChat = false;

          if (!window.HubSpotConversations?.widget?.status()?.loaded) {
            this.chatNotLoaded = true;
          }

          this.cdRef.detectChanges();
        });
    } else {
      this.loadingChat = false;
      this.cdRef.detectChanges();
    }

    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.load();
      this.cdRef.detectChanges();
    } else {
      window.hsConversationsOnReady = [
        () => {
          window.HubSpotConversations.widget.load();
          this.cdRef.detectChanges();
        },
      ];
    }
  }

  onHideBubble(): void {
    this.hiding = true;

    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.remove();
    }

    this.store
      .dispatch(new HideChatBubble())
      .pipe(
        switchMap(() => this.bubbleHideDone$),
        take(1),
      )
      .subscribe(() => {
        this.hiding = false;

        this.store.dispatch(
          new DisplaySnackbar($localize`Help can be found from the user menu`, {
            color: 'primary',
            actionName: $localize`Show`,
            actionCallback: () => this.store.dispatch(new OpenUserMenu({ highlightHelp: true })),
          }),
        );
      });
  }

  onMenuClosed(): void {
    // empty to trigger change detection (bug material)
  }

  onMenuOpened(): void {
    if (this.chatOpen) {
      this.menuTrigger.closeMenu();
      this.chatOpen = false;
    }

    if (this.aiChatOpen) {
      this.menuTrigger.closeMenu();
      this.aiChatOpen = false;
    }
  }

  onBubbleScaleDone(event: AnimationEvent): void {
    if (event.toState == null) {
      this.bubbleShowDone$.next();
    } else if (event.fromState == null) {
      this.bubbleHideDone$.next();
    }
  }

  onDragStart(): void {
    this.dragging = true;
    this.dragMenuOpen = this.menuTrigger?.menuOpen;

    if (this.dragMenuOpen) {
      this.menuTrigger.closeMenu();
    }
  }

  onDragEnd(): void {
    this.dragging = false;

    if (this.dragMenuOpen) {
      this.menuTrigger.openMenu();
    }

    this.dragMenuOpen = false;
  }
}
