import { Injectable } from '@angular/core';

import { Actions, NgxsOnInit, ofActionDispatched, State, StateContext } from '@ngxs/store';

import { SnackbarService } from '@shared/services/snackbar.service';
import { DialogControl } from '@shared/services/dialog-control.service';
import { SidenavService } from '@shared/modules/sidenav/services/sidenav.service';
import { ActionsStateModel } from '@shared/states/actions.state';
import { DialogClosed, DisplaySnackbar, OpenDialog, OpenSidenav, SidenavClosed } from '@shared/states/dialog.actions';

@Injectable()
@State({
  name: 'dialog',
})
export class DialogState implements NgxsOnInit {
  constructor(
    private a$: Actions,
    private dc: DialogControl,
    private ss: SidenavService,
    private ns: SnackbarService,
  ) {}

  ngxsOnInit({ dispatch }: StateContext<ActionsStateModel>): void {
    this.a$.pipe(ofActionDispatched(OpenDialog, OpenSidenav, DisplaySnackbar)).subscribe((action) => {
      if (action instanceof OpenDialog) {
        this.dc
          .open(action.component, null, action.config)
          .afterClosed()
          .subscribe((response) => dispatch(new DialogClosed(action.component, response)));
      } else if (action instanceof OpenSidenav) {
        this.ss
          .openSidenav(action.component, action.config)
          .sidenav.close.subscribe((response) => dispatch(new SidenavClosed(action.component, response)));
      } else if (action instanceof DisplaySnackbar) {
        this.ns.open(action.title, action.options);
      }
    });
  }
}
