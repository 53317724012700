const methods = [
  'log',
  'info',
  'trace',
  'warn',
  'clear',
  'time',
  'timeEnd',
  'timeLog',
  'timeStamp',
  'assert',
  'count',
  'debug',
  'dir',
  'dirxml',
  'exception',
  'group',
  'groupCollapsed',
  'groupEnd',
  'markTimeLine',
  'profile',
  'profileEnd',
  'table',
  'timeline',
  'timelineEnd',
];

// We leave error logging since those are send to Sentry

export function disableConsole() {
  const shouldDisable =
    window &&
    window.location &&
    typeof window.location.href === 'string' &&
    window.location.href.includes('zef.fi') &&
    !window.location.href.includes('zefDebug');

  if (window?.console && shouldDisable) {
    methods.forEach((method) => ((window as any).console[method] = () => void 0));
  } else if (typeof window.console?.log === 'function') {
    console.log('%cCONSOLE ENABLED', 'color: green; font-weight: bold;');
  }
}
