import { Injectable } from '@angular/core';

import { Action, State, StateContext } from '@ngxs/store';

import { UpdateChatPrefs } from '@shared/states/prefs.actions';
import { CHAT_STATE_TOKEN, ChatStateModel } from '@shared/modules/chat/chat.models';
import { HideChatBubble, ShowChatBubble, StartChat, StopChat } from '@shared/modules/chat/chat.actions';

@Injectable()
@State<ChatStateModel>({
  name: CHAT_STATE_TOKEN,
  defaults: {
    plan: 'free_plan',
    team: '',
    user: '',
    email: '',
    rights: '',
    survey: '',
    activity: '',
    language: '',
    chatting: false,
  },
})
export class ChatState {
  constructor() {}

  @Action(StartChat)
  startChat(): void {}

  @Action(StopChat)
  stopChat(): void {}

  @Action(ShowChatBubble)
  showChatBubble({ dispatch }: StateContext<ChatStateModel>): void {
    dispatch(new UpdateChatPrefs({ helpHidden: false }));
  }

  @Action(HideChatBubble)
  hideChatBubble({ dispatch }: StateContext<ChatStateModel>): void {
    dispatch(new UpdateChatPrefs({ helpHidden: true }));
  }
}
