import { Injectable } from '@angular/core';
import { Data, Params, RouterStateSnapshot } from '@angular/router';

import { RouterStateSerializer } from '@ngxs/router-plugin';

export interface RouterStateParams {
  url: string;
  previousUrl?: string;
  fragment: string;
  params: Params;
  queryParams: Params;
  data: Data;
}

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateParams> {
  serialize(routerState: RouterStateSnapshot): RouterStateParams {
    const {
      url,
      root: { queryParams, fragment },
    } = routerState;

    let { root: route } = routerState;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const { params, data } = route;

    return { url, params, queryParams, fragment, data };
  }
}
