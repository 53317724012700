export function assertLocale(): string {
  let locale = localStorage.getItem('zef.locale') || null;

  const lang = ((window.location.search.match(/[\\?&]lang=([^&]*)/i) || [])[1] || '').toLowerCase();

  if (window.location.href.indexOf('localhost') === -1) {
    if (lang === 'en') {
      window['lang'] = lang;
    } else if (lang === 'fi') {
      window['lang'] = lang;
    } else if (locale === 'fi') {
      window['lang'] = locale;
    } else if (!lang && !locale) {
      if (window.navigator.language.indexOf('fi') !== 0) {
        window['lang'] = 'en';
      } else {
        window['lang'] = 'fi';
      }
    }

    locale = window['lang'] || lang || locale;
  }

  return locale;
}
