import { loadTranslations, MessageId, TargetMessage } from '@angular/localize';

export interface ParsedTranslationBundle {
  locale: string;
  translations: Record<MessageId, TargetMessage>;
}

export function getTranslations(locale: string): Promise<ParsedTranslationBundle> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      try {
        const res = JSON.parse(xhr.responseText);
        preLoadTranslations(res);
        resolve(res);
      } catch (e) {
        reject(e);
      }
    };

    xhr.onerror = () => reject(new Error('Network request failed'));
    xhr.ontimeout = () => reject(new Error('Network request timeout'));
    xhr.onabort = () => reject(new Error('Network request aborted'));

    xhr.open('GET', `/locales/messages.${locale}.json`);
    xhr.send();
  });
}

function preLoadTranslations(parsedTranslationBundle: ParsedTranslationBundle) {
  loadTranslations(parsedTranslationBundle.translations);
  window.$localize.locale = parsedTranslationBundle.locale;
}
