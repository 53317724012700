import { environment } from '@env/environment';

import { Subject } from 'rxjs';

import { ErrorHandler, Injectable } from '@angular/core';

import * as Sentry from '@sentry/angular-ivy';
import { SentryErrorHandler } from '@sentry/angular-ivy';
import { captureConsoleIntegration } from '@sentry/integrations';

@Injectable()
export class ZefErrorHandler extends ErrorHandler {
  public error: Subject<any> = new Subject<Error | null>();

  private sentryErrorHandler?: SentryErrorHandler;

  constructor() {
    super();

    if (!environment.debug) {
      this.initSentry();
    }
  }

  async initSentry(): Promise<void> {
    const { version } = await import('../../../package.json');

    Sentry.init({
      dsn: 'https://05066c638e684ea4b38bdf2fe9e0ebca@sentry.zef.fi/2',
      release: version,
      environment: 'prod',
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
      ],
      integrations: [captureConsoleIntegration({ levels: ['error'] })],
    });

    this.sentryErrorHandler = new SentryErrorHandler({});
  }

  public clearError() {
    this.error.next(null);
  }

  public handleError(error: Error) {
    this.error.next(error);

    this.sentryErrorHandler?.handleError(error);

    if (error) {
      super.handleError(error);
    }
  }
}
