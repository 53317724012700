<div class="z-fx-col z-fx-start-center chat-container">
  <div class="z-fx z-fx-center-center z-fx-w100 chat-header">
    <mat-icon class="reset-button" (click)="reset()">reset</mat-icon>
    <div class="zef-text-p1 chat-header-text" i18n>Support chat</div>
    <mat-icon class="close-button" (click)="closeChat.emit()">close</mat-icon>
  </div>

  <div
    *ngIf="messages?.length || (loading$ | async)"
    class="z-fx-col z-fx-auto z-fx-w100 z-fx-start-start"
    (mousedown)="$event.stopPropagation()"
  >
    <ng-scrollbar class="z-fx-w100" [autoHeightDisabled]="false">
      <div class="z-fx-col z-fx-auto z-fx-gap-8 chat-messages">
        <ng-container *ngFor="let message of messages; trackBy: trackByMessage; let i = index">
          <div
            *ngIf="message.role === 'assistant' || message.role === 'user'"
            class="zef-text-p2 message"
            [class.user]="message.role === 'user'"
            [class.assistant]="message.role === 'assistant'"
          >
            {{ message?.message }}
          </div>
        </ng-container>
        <div *ngIf="loading$ | async" class="dot-typing"></div>
      </div>
    </ng-scrollbar>
  </div>

  <div *ngIf="!messages?.length && (loading$ | async) === false" class="z-fx z-fx-auto z-fx-center-center">
    <mat-icon class="error-icon">placeholder</mat-icon>
  </div>
  <div class="z-fx z-fx-none z-fx-gap-4 chat-input-area">
    <mat-form-field class="zef-align-top">
      <mat-label>
        <ng-container i18n="placeholder|support chat input">Type your message here...</ng-container>
      </mat-label>
      <textarea
        matInput
        #input
        class="emails-input"
        cdkFocusInitial
        [value]="''"
        (keyup.enter)="addComment(input.value); input.value = ''"
        (input)="onValueChanged()"
      ></textarea>
    </mat-form-field>
    <div class="z-fx z-fx-center-center send-button-container">
      <button
        mat-icon-button
        class="send-button"
        [disabled]="!input?.value"
        (click)="addComment(input.value); input.value = ''"
      >
        <mat-icon>send</mat-icon>
      </button>
    </div>
  </div>
  <div class="chat-info" i18n>This is an AI service that can make mistakes.</div>
</div>
